.login-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 0 2rem 0;
    text-align: center;
}

.response-box {
    white-space: pre-line;
}

@media only screen and (max-width: 768px) {
    .login-body {
        justify-content: space-between;
    }
  }