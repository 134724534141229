.question-box {
    border: 1px solid var(--grayLight);
    border-radius: 8px;
}

.question-box-cta {
    background-color: var(--grayLight);
    padding: 10px 16px;
}

.rating-wrapper {
    border-bottom: 2px solid var(--primary);
    border-radius: 0px;
    margin-bottom: 16px;
}

.footer audio {
    display: block;
}

audio {
    display: none;
}