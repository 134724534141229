.w-md-50 {
    width: 50%;
    max-width: 450px;
  }


.primary-gradient {
    background-image: linear-gradient(var(--primary) 0%, var(--primaryDark) 25%);
    width: 100%;
  }

  .body-wrapper {
    display: flex;
    justify-content: center;  
  }

  @media only screen and (max-width: 768px) {
    .w-md-50 {
        width: 100%;
      }
  }