.footer {
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 1.25rem;
    left: 50%;
    background: transparent;
    z-index: 1000;
    border-top: 1px solid var(--greyLight);
    max-width: 450px;
    margin: 0xp auto;
    transform: translate(-50%, 0px);
  }