.btn {
  min-height: 56px;
  font-weight: 600;
  border-radius: 8px;
  padding: 1rem;
}

.btn-primary {
  background-color: var(--secondary);
  color: var(--black);
  transition: 1s;
  border-color: var(--secondary);
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--black);
  transform: scale(1.03);
  box-shadow: none;
}

.option-btn {
    background-color: var(--gray);
    border-radius: 8px;
    border: 1px solid var(--grayLight);
    padding: 1rem;
    margin-bottom: 12px;
}

.option-btn h3 {
    color: var(--white);
}

.option-btn:hover {
    background-color: var(--white);
}

.option-btn:hover h3 {
    color: var(--black);
}
