:root {

  /* Primary colors for the app */
  --primary: #674dbe;
  --primaryDark: #161616;

  /* Secondary colors for the app */
  --secondary: #fcff67;

  /* Basic color */
  --white: #ffffff;
  --black: #000000;

  /* Shades of gray */
  --gray: #383838;
  --grayLight: #444444;
  --textGray: #626262;

  --green: #1B923C;


}


.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}


