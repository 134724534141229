.doc-upload-cta {
    background-color: var(--gray);
    padding: 60px;
    border-radius: 8px;
    border: 1px dotted var(--grayLight); 
    cursor: pointer;
}

.coming-soon-div {
    padding-top: 30px;
}

  li::marker {
    color: white;
    
  }

  .multiple-option-box {
    border-radius: 8px;
    padding: 14px 12px;
    font-size: 16px;
  }

  .multiple-option-box.selected {
    background-color: var(--white);
    color: var(--black);
    border: 2px solid var(--green);
  }

  .multiple-option-box.not-selected {
    background-color: var(--grayLight);
    color: var(--white);
  }