.primary-input {
    padding: 12px;
    background-color: var(--gray);
    color: var(--white);
    border: 2px solid var(--grayLight);
    border-radius: 8px;
}

.primary-input::placeholder {
    color: var(--textGray);
    font-weight: 600;
}

.primary-input:focus {
    background-color: var(--gray);
    border: 3px solid var(--grayLight);
    box-shadow: none;
    color: var(--white);
}